<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <!-- <div><router-link to="/Admin/menus/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div> -->
                    
                    <div><el-input v-model="where.F_VR_NIC" placeholder="请输入发布者昵称"></el-input></div>
                    <!-- <div style="width:130px;"><el-date-picker format="yyyy-MM-dd HH:mm" type="date" placeholder="开始日期" v-model="where.times[0]" style="width: 100%;"></el-date-picker></div> -->
                    <div><el-date-picker format="yyyy-MM-dd HH:mm" v-model="where.times" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker></div>
                    <!--<div style="width:160px;">
                        <el-select v-model="where.type" placeholder="选择类型">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="未支付" value="NOPAY"></el-option>
                            <el-option label="已支付" value="WAITSEND"></el-option>
                            <el-option label="等待收货" value="WAITREC"></el-option>
                            <el-option label="等待评论" value="WAITCOMMENT"></el-option>
                            <el-option label="订单完成" value="COMPLETE"></el-option>
                            <el-option label="售后处理" value="REFUND"></el-option>
                            <el-option label="取消订单" value="CLOSE"></el-option>
                        </el-select>
                    </div>-->
                    <div><el-button icon="el-icon-search" @click="get_order_list()">条件筛选</el-button></div>
                </div>
                <div class="admin_main_block_right">
                    <div><el-button type="success" icon="el-icon-default" @click="xxts(select_id)">消息推送</el-button><el-button type="success" icon="el-icon-default" @click="tuij(select_id)">批量推荐</el-button><el-button type="success" icon="el-icon-default" @click="tuijqx(select_id)" style="margin-right:20px;">批量推荐取消</el-button><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
                <!--<div class="admin_main_block_right">
                    <div><el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button></div>
                </div>-->
            </div>
            <div class="admin_table_main">
                <el-table :data="list" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column prop="F_IN_ID" label="#"  width="50px"></el-table-column>
                    <el-table-column label="发布者" width="160px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><el-image style="width: 50px; height: 50px" :src="scope.row.F_VR_TOUX"><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></dt>
                                <dd class="table_dl_dd_all" :title="scope.row.F_VR_NIC">{{ scope.row.F_VR_NIC }}</dd>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column prop="F_TE_DONGTNR" label="动态内容" width="430px"></el-table-column>
                    <el-table-column label="图片" width="80px">
                        <template slot-scope="scope">
                            <dl class="table_dl" v-if="scope.row.F_TI_FABLX==1">
                                <dt>
                                    <el-popover placement="left" trigger="click" width="600">
                                        <img :src="scope.row.F_TE_TUPHSP||require('@/assets/default_pic.png')" width="100%" />
                                        <img
                                                slot="reference"
                                                :src="scope.row.F_TE_TUPHSP||require('@/assets/default_pic.png')"
                                                :alt="scope.row.F_TE_TUPHSP"
                                                style="max-height: 60px;max-width: 60px; padding: 5px"
                                        />
                                    </el-popover>
                                </dt>
                                <!--<dd class="table_dl_dd_all" :title="scope.row.F_VR_NIC">{{ scope.row.F_VR_NIC }}</dd>-->
                            </dl>
                            <dl class="table_dl" v-if="scope.row.F_TI_FABLX==2">
                                <dt>
                                    <el-popover placement="left" trigger="click" width="600">
                                        <img :src="scope.row.F_VR_FENGM||require('@/assets/default_pic.png')" width="100%" />
                                        <img
                                                slot="reference"
                                                :src="scope.row.F_VR_FENGM||require('@/assets/default_pic.png')"
                                                :alt="scope.row.F_VR_FENGM"
                                                style="max-height: 60px;max-width: 60px; padding: 5px"
                                        />
                                    </el-popover>
                                </dt>
                                <!--<dd class="table_dl_dd_all" :title="scope.row.F_VR_NIC">{{ scope.row.F_VR_NIC }}</dd>-->
                            </dl>
                        </template>
                    </el-table-column>
                    <!--<el-table-column prop="F_VR_ZUOZ_NIC" label="作者"></el-table-column>
                    <el-table-column prop="F_VR_CHUC" label="出处"></el-table-column>-->
                    <!--<el-table-column label="订单状态">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.cn_status=='订单完成'">{{scope.row.cn_status}}</el-tag>
                            <el-tag type="warning" v-else-if="scope.row.cn_status=='售后处理'">{{scope.row.cn_status}}</el-tag>
                            <el-tag v-else-if="scope.row.cn_status=='等待发货'">{{scope.row.cn_status}}</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.cn_status=='等待支付'">{{scope.row.cn_status}}</el-tag>
                            <el-tag type="info" v-else>{{scope.row.cn_status}}</el-tag>
                        </template> 
                    </el-table-column>-->
                    <!-- <el-table-column label="PC推荐首页">
                        <template slot-scope="scope">
                            <div :class="scope.row.is_index==1?'green_round':'gray_round'" @click="goods_index(scope.row.id)"></div>
                        </template> 
                    </el-table-column> -->
                    <!--<el-table-column prop="total_price" label="价格"></el-table-column>-->
                    <el-table-column label="排序" width="140px">
                        <template slot-scope="scope">
                            <el-input type="number" @blur="sortChange(scope.row)" v-model="scope.row.is_sort"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="广场推荐">
                        <template slot-scope="scope">
                            <div :class="scope.row.F_TI_GUANGCTJ==1?'green_round':'gray_round'" @click="dongt_status(scope.row.F_IN_ID)"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="审核状态" width="100px">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_ZHUANGT==1">通过</el-tag>
                            <el-tag type="warning" v-else-if="scope.row.F_TI_ZHUANGT==2">审核不通过</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间">
                        <template slot-scope="scope">
                            <div>{{scope.row.F_IN_CHUANGJSJ|formatDate}}</div>
                        </template> 
                    </el-table-column>


                    <el-table-column label="操作" fixed="right" width="220px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'admin_dongt_info',params:{F_IN_ID:scope.row.F_IN_ID}})">编辑</el-button>
                            <el-button icon="el-icon-edit" :disabled="scope.row.goods_verify==1 || scope.row.goods_verify==2" @click="verify_click(scope.row.F_IN_ID)">审核</el-button>
                            <!--<el-button icon="el-icon-edit" @click="$router.push('/Admin/order/info/'+scope.row.id)">查看订单</el-button>
                            &lt;!&ndash; <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> &ndash;&gt;-->
                        </template>
                    </el-table-column>

                    <!--<el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push('/Admin/order/info/'+scope.row.id)">查看订单</el-button>
                            &lt;!&ndash; <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> &ndash;&gt;
                        </template>
                    </el-table-column>-->
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>

        <el-dialog title="推送消息" :visible.sync="dialogFormVisible"  @closed="handleClose" width="520px">
            <el-form :model="form" :rules="rules" ref="ruleForm">
                <el-form-item label="标题" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="内容" :label-width="formLabelWidth" prop="neir">
                    <el-input v-model="form.neir" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>





    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          list:[],
          total_data:0, // 总条数
          page_size:20,
          current_page:1,
          select_id:'',
          goods_verify_count:0,
          where:{
              times:[],
              type:'',
              F_VR_NIC:'',
          },




          //dialogFormVisible: true,
          dialogFormVisible: false,
          form: {
              name: '',
              neir: ''
          },
          formLabelWidth: '80px',
          rules: {
              name: [
                  { required: true, message: '请输入标题', trigger: 'blur' },
                  { min: 2, max: 255, message: '长度在 2 到 255 个字符', trigger: 'blur' }
              ],
              neir: [
                  { required: true, message: '请输入内容', trigger: 'blur' },
                  { min: 2, max: 255, message: '长度在 2 到 255 个字符', trigger: 'blur' }
              ]
          },
          id:''



      };
    },
    watch: {},
    computed: {},
    methods: {


        // 消息推送
        xxts:function(F_IN_ID){
            if(this.$isEmpty(F_IN_ID)){
                return this.$message.error('请先选择推荐的对象');
            }
            this.id = F_IN_ID;
            this.dialogFormVisible = true;
        },
        handleClose () {
            this.$refs.ruleForm.resetFields()
            this.form = {
                name: '',
                neir: ''
            }
        },
        handleSave () {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    console.log(this.id)
                    console.log(this.form.name)
                    console.log(this.form.neir)
                    console.log('输入正确')
                    this.$post(this.$api.xxtsDongt,{id:this.id,name:this.form.name,neir:this.form.neir}).then(res=>{
                    });
                    this.dialogFormVisible = false
                    this.get_order_list();
                    return this.$message.success("推送成功");
                } else {
                    console.log('输入错误')
                }
            })
        },







        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.F_IN_ID);
            });
            this.select_id = ids.join(',');
        },
        get_order_list:function(){
            let where = {};
            where.page = this.current_page;
            where.params = this.where;
            this.$get(this.$api.getDongtList,where).then(res=>{
                this.page_size = res.data.per_page;
                this.total_data = res.data.total;
                this.list = res.data.data;
                this.current_page = res.data.current_page;
            })

        },
        // 推荐处理
        tuij:function(F_IN_ID){

            if(this.$isEmpty(F_IN_ID)){
                return this.$message.error('请先选择推荐的对象');
            }
            this.$post(this.$api.tuijDongt,{F_IN_ID:F_IN_ID}).then(res=>{
                if(res.code == 200){
                    this.get_order_list();
                    return this.$message.success("推荐成功");
                }else{
                    return this.$message.error("推荐失败");
                }
            });
        },
        // 推荐取消处理
        tuijqx:function(F_IN_ID){
            if(this.$isEmpty(F_IN_ID)){
                return this.$message.error('请先选择推荐取消的对象');
            }
            this.$post(this.$api.tuijqxDongt,{F_IN_ID:F_IN_ID}).then(res=>{
                if(res.code == 200){
                    this.get_order_list();
                    return this.$message.success("推荐取消成功");
                }else{
                    return this.$message.error("推荐取消失败");
                }
            });
        },
        // 删除处理
        del:function(F_IN_ID){
             if(this.$isEmpty(F_IN_ID)){
                 return this.$message.error('请先选择删除的对象');
             }
             this.$post(this.$api.delDongt,{F_IN_ID:F_IN_ID}).then(res=>{
                 if(res.code == 200){
                    this.get_order_list();
                     return this.$message.success("删除成功");
                 }else{
                     return this.$message.error("删除失败");
                 }
             });
        },
        verify_click:function(F_IN_ID){

            this.$confirm('此操作将控制动态是否通过审核, 是否继续?', '提示', {
                    confirmButtonText: '同意',
                    cancelButtonText: '拒绝',
                    type: 'info'
                }).then(() => {
                    this.$post(this.$api.dongtVerifyChange,{F_IN_ID:F_IN_ID,status:1}).then(()=>{
                        this.$message.success('成功审核');
                        this.get_order_list();
                    });

                }).catch(() => {
                    this.$post(this.$api.dongtVerifyChange,{F_IN_ID:F_IN_ID,status:2}).then(()=>{
                        this.$message.info('已拒绝');
                        this.get_order_list();
                    });

                });
        },
        // 排序移动
        sortChange(rows){
            console.log(666)
            console.log(rows)
            this.$post(this.$api.updateDongt,{F_IN_ID:rows.F_IN_ID,is_sort:rows.is_sort}).then(res=>{
                if(res.code == 200){
                    this.get_order_list();
                    return this.$message.success(res.msg)
                }else{
                    return this.$message.success(res.msg)
                }
            });
        },
        // 指定ID修改状态
        dongt_status:function(F_IN_ID){
            this.$post(this.$api.adminDongtStatus,{F_IN_ID:F_IN_ID}).then(res=>{
                if(res.code==200){
                    this.$message.success('操作成功');
                }else{
                    this.$message.success('操作失败');
                }
                this.get_order_list();
            });
        },
        current_change:function(e){
            this.current_page = e;
            this.get_order_list();
        },
    },
    created() {
        this.get_order_list();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
.table_dl_dd_all{
    height: 50px;
    overflow: hidden;
}
.admin_main_block_right {
    width: 50%;
    float: right;
    margin-right: 15px;
}

</style>